<template>
  <div v-if="data && data.length > 0" class="col-12 col-xl-4 col-md-6 d-flex">
    <div class="section mb-4">
      <div class="graph-head pb-1">
        <div class="graph-head__title">
          <h2 class="pt-2">{{ formatLabel(title) }}</h2>
        </div>
      </div>
      <div class="chart-circle">
        <highcharts :options="chartOptions"></highcharts>
      </div>
      <div v-if="data" class="graph-caption">
        <div v-for="(el, idx) in data" class="graph-caption__item" :key="idx">
          <div class="graph-caption__group">
            <div
              class="graph-caption__circle"
              :style="`border-color: ${
                colors[idx <= colors.length - 1 ? idx : idx - colors.length]
              }`"
            ></div>
            <div class="graph-caption__label">{{ el.name }}</div>
          </div>
          <div class="graph-caption__value">{{ el.data[0] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";

export default {
  name: "CircleChart",
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: null,
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      colors: [
        "#799CD3",
        "#F4C374",
        "#6DA29A",
        "#8766E6",
        "#E66666",
        "#65D384",
      ],
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 260,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Roboto",
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
          headerFormat: null,
          backgroundColor: "#fff",
          borderWidth: 1,
          padding: 10,
          shadow: {
            color: "#000",
            width: 30,
            opacity: 0.003,
          },
          borderColor: "#fff",
          borderRadius: 10,
          style: {
            fontSize: "13px",
            color: "#000",
          },
          formatter: function () {
            return (
              this.key +
              " <strong>" +
              this.point.data[0].toLocaleString("ru") +
              "</strong>"
            );
          },
        },
        plotOptions: {
          pie: {
            innerSize: "40%",
            allowPointSelect: false,
            cursor: "pointer",
            borderWidth: 0,
            borderRadius: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: false,
            colors: [],
          },
        },
        series: [
          {
            colorByPoint: true,
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    data() {
      this.chartOptions.series[0].data = this.data;
    },
  },
  mounted() {
    this.chartOptions.plotOptions.pie.colors = this.colors;
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
  },
};
</script>