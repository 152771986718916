<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between mob-mb-15">
      <div class="col-12 col-md-auto dashboard-header-wrapper">
        <h1>
          {{ $t("dashboard.title") }}
        </h1>
        <span>{{ $t("today") }}, {{ currentDate }}</span>
      </div>
    </div>
    <FormatList :tabs="formats" :currentTab="currentFormat" :textPath="'dashboard.tabs'" @setCurrentTab="setCurrentTab" class="mb-4" />
    <GeneralInfo :block="currentHeaderInfo" class="mb-4" />
    <div class="row">
      <StatisticChart :data="statisticChart" @toggleChart="toggleChart" />
      <IncomeChart :data="incomeChart" />
      <LinesChart @toggleChart="toggleHoursChart" @setCurrentValue="getCurrentHourGroup" :title="$t('statistic.by_hours')" :series="hoursStats" />
      <CircleChart :data="circleCountries" :title="'statistic.all_countries'" />
      <CircleChart :data="circleBrowsers" :title="'statistic.all_browsers'" />
      <CircleChart :data="circlePlatforms" :title="'statistic.all_platforms_2'" />
    </div>
    <div class="sep"></div>
    <template v-if="currentFormat === 'push'">
      <h2 class="title-h2">{{ $t("dashboard.top_landing") }}</h2>
      <div class="c-table">
        <tableAnimation />
        <div class="inner">
          <vue-good-table styleClass="table  _no-wrap" :columns="columns" :rows="rows">
            <template slot="table-column" slot-scope="props">
              <span>
                {{ formatLabel(props.column.label) }}
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <template v-if="props.column.field === 'id'">
                {{ rows.findIndex((el) => el.id === props.row.id) + 1 }}
              </template>
              <template v-if="props.column.field === 'name'">
                {{ $i18n.locale === "ru" ? props.row.name_ru : props.row.name_en }}
              </template>
              <template v-else-if="props.column.field === 'ratio'">
                {{ props.row.ratio }}
              </template>
              <template v-else-if="props.column.field === 'control'">
                <ul class="controls">
                  <li>
                    <button @click="openLandModal(props.row)" class="link _green dashboard-button">
                      <svg v-svg symbol="view" size="0 0 15 15" role="info" />

                      {{ $t("platform.arbitrage.watch") }}
                    </button>
                  </li>
                </ul>
              </template>
            </template>
            <div class="d-flex justify-content-center" slot="emptystate">
              <template v-if="!loadDataTable">
                {{ $t("any_results") }}
              </template>
              <template v-else>
                <div class="d-flex justify-content-center">
                  <img :src="require('@/assets/images/rolling.svg')" alt="loader" class="loader" />
                </div>
              </template>
            </div>
          </vue-good-table>
        </div>
      </div>
    </template>
    <modal @closed="clearLandingModal" :width="780" name="landModal" height="auto" :scrollable="true" classes="creative-modal" :shiftY="0">
      <LandingModal :landing="currentLanding" @closeModal="closeLandingModal" />
    </modal>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ServicePanel from "@/api/servicePanel";
import dashboardService from "@/api/dashboardService";
import FormatList from "@/components/FormatList.vue";
import GeneralInfo from "@/components/GeneralInfo.vue";
import CircleChart from "@/components/Charts/CircleChart.vue";
import LinesChart from "@/components/Charts/LinesChart.vue";
import StatisticChart from "@/components/Charts/StatisticChart.vue";
import IncomeChart from "@/components/Charts/IncomeChart.vue";
import LandingModal from "@/components/LandingModal";
import TableAnimation from "@/components/TableAnimation";
import LandingMixin from "@/mixins/landModal";
import "@/assets/css/_section.scss";
import "@/assets/css/dashboard.scss";

export default {
  name: "Dashboard",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("dashboard.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("dashboard.title")}`;
  },
  components: {
    FormatList,
    GeneralInfo,
    CircleChart,
    LinesChart,
    StatisticChart,
    IncomeChart,
    LandingModal,
    TableAnimation,
  },
  mixins: [LandingMixin],
  data() {
    return {
      formats: ["push", "inpage", "onclick"],
      currentFormat: "push",
      headerInfo: null,

      hoursStats: null,
      circleCountries: null,
      circleBrowsers: null,
      circlePlatforms: null,
      statisticChart: null,
      incomeChart: null,

      hourData: null,
      hourCurrentGroup: null,

      loadDataTable: false,
      columns: [
        {
          label: "",
          field: "id",
          sortable: false,
        },
        {
          label: "support.table.name",
          field: "name",
          sortable: false,
        },
        {
          label: "dashboard.general_info.ratio",
          field: "ratio",
          sortable: false,
        },
        {
          label: "",
          field: "control",
          width: "170px",
          sortable: false,
        },
      ],
      rows: null,
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.statisticChart = this.statisticChart.map((el) => ({
        ...el,
        name: " - " + this.formatLabel(`dashboard.general_info.${el.id}`),
        title: this.formatLabel(`dashboard.general_info.${el.id}`),
      }));

      this.hoursStats = this.hoursStats.map((el) => ({
        ...el,
        name: " - " + this.formatLabel(el.title),
      }));

      this.changeDiagramNames();
    },
    currentFormat() {
      this.getDashboardCharts();
    },
  },
  mounted() {
    if (this.$route.query.secret) {
      ServicePanel.verifyWallet({ secret: this.$route.query.secret }).then((res) => {
        if (res && res.status === 200) {
          this.$alert({
            title: this.$t("payment_change"),
            type: "success",
            time: 5000,
          });
        }
      });
    }
    dashboardService.getTotalStatistic().then((res) => {
      if (res && res.status === 200) {
        this.headerInfo = res.data;
      }
    });
    this.getDashboardCharts();
  },
  computed: {
    ...mapGetters({
      selectCountries: "statistics/getCountries",
    }),

    currentDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    },
    currentHeaderInfo() {
      return this.headerInfo ? Object.entries(this.headerInfo[this.currentFormat]) : null;
    },
  },
  methods: {
    getDashboardCharts() {
      this.loadDataTable = true;

      const payload = {
        format: this.formats.findIndex((el) => el === this.currentFormat),
      };

      dashboardService.getStatisticChart(payload).then((res) => {
        if (res && res.status === 200) {
          const arr = [
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"views"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"views"}`),
              id: "views",
              show: true,
              color: "#5D8ED8",
              data: null,
              yAxis: 0,
            },
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"clicks"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"clicks"}`),
              id: "clicks",
              show: true,
              color: "#F4C374",
              data: null,
              yAxis: 1,
            },
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"income"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"income"}`),
              id: "income",
              show: true,
              color: "#489C96",
              data: null,
              yAxis: 2,
            },
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"cpc"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"cpc"}`),
              id: "cpc",
              show: true,
              color: "#D55F74",
              data: null,
              yAxis: 3,
            },
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"cpm"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"cpm"}`),
              id: "cpm",
              show: true,
              color: "#74C0C0",
              data: null,
              yAxis: 4,
            },
            {
              name: ` - ${this.formatLabel(`dashboard.general_info.${"ctr"}`)}`,
              title: this.formatLabel(`dashboard.general_info.${"ctr"}`),
              id: "ctr",
              show: true,
              color: "#7DB188",
              data: null,
              yAxis: 5,
            },
          ];

          this.statisticChart = arr.map((el) => ({
            ...el,
            data: res.data[el.id],
          }));
        }
      });

      dashboardService.getIncomeChart(payload).then((res) => {
        if (res && res.status === 200) {
          const setColor = (value) => {
            return value.includes("-") ? "#B75D5D" : "#28A662";
          };
          const incomeChart = Object.assign({}, res.data);

          incomeChart.yesterday.color = setColor(incomeChart.yesterday.profit);
          incomeChart.today.color = setColor(incomeChart.today.profit);

          this.incomeChart = incomeChart;
        }
      });

      dashboardService.getHourChart(payload).then((res) => {
        if (res && res.status === 200) {
          this.hourData = res.data;
          this.setHourStats();
        }
      });

      dashboardService
        .getDiagramChart(payload)
        .then((res) => {
          if (res && res.status === 200) {
            this.circleBrowsers = res.data.browsers;
            this.circleCountries = res.data.countries.map((el) => ({
              ...el,
              iso: el.name,
            }));
            this.circlePlatforms = res.data.os;
          }
        })
        .finally(() => {
          this.changeDiagramNames();
        });

      dashboardService
        .getTopLanding(payload)
        .then((res) => {
          if (res && res.status === 200) {
            this.rows = res.data;
          }
        })
        .finally(() => {
          this.loadDataTable = false;
        });
    },

    changeDiagramNames() {
      this.circleCountries = this.circleCountries.map((el) => {
        const matchingCountry = this.selectCountries.find((country) => country.iso === el.iso);
        if (matchingCountry) {
          const nameField = this.$i18n.locale === "ru" ? "name_ru" : "name_en";
          return {
            ...el,
            name: matchingCountry[nameField],
          };
        }
        return el;
      });
    },

    toggleHoursChart(index) {
      let count = 0;
      for (let el of this.hoursStats) {
        if (el.show) count++;
      }
      if (count > 1 || !this.hoursStats[index].show) this.hoursStats[index].show = !this.hoursStats[index].show;
    },
    getCurrentHourGroup(e) {
      this.hourCurrentGroup = e;
      if (this.hourData) {
        this.setHourStats();
      }
    },
    setHourStats() {
      this.hoursStats = [
        {
          name: "- " + this.formatLabel("today"),
          title: "today",
          show: true,
          marker: {
            symbol: "circle",
            radius: 3,
          },
          lineWidth: 1,
          color: "#489C96",
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          data: this.hourData[this.hourCurrentGroup.id].today,
        },
        {
          name: "- " + this.formatLabel("yesterday"),
          title: "yesterday",
          show: true,
          marker: {
            symbol: "circle",
            radius: 3,
          },
          lineWidth: 1,
          color: "#5D8ED8",
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          data: this.hourData[this.hourCurrentGroup.id].yesterday,
        },
        {
          name: "- " + this.formatLabel("yesterday2"),
          title: "yesterday2",
          show: true,
          marker: {
            symbol: "circle",
            radius: 3,
          },
          lineWidth: 1,
          color: "#D55F74",
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          data: this.hourData[this.hourCurrentGroup.id].before_yesterday,
        },
      ];
    },
    setCurrentTab(e) {
      this.currentFormat = e;
    },
    formatLabel(el) {
      return this.$t(el);
    },
    toggleChart(idx) {
      let count = 0;
      for (let el of this.statisticChart) {
        if (el.show) count++;
      }
      if (count > 1 || !this.statisticChart[idx].show) this.statisticChart[idx].show = !this.statisticChart[idx].show;
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-header-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0 26px;

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 4;
  }
}

.dashboard-button {
  background: transparent;
  color: #6da29a;

  svg {
    fill: #6da29a;
  }

  &:focus,
  :active {
    outline: none;
  }
}
</style>
