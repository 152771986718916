export default {
  data() {
    return {
      currentLanding: null,
    };
  },
  methods: {
    clearLandingModal() {
      this.currentLanding = null;
    },
    openLandModal(land) {
      this.currentLanding = land;
      this.$modal.show("landModal");
    },
    closeLandingModal() {
      this.$modal.hide("landModal");
      this.clearLandingModal();
    },
  },
};
