<template>
  <div class="modal-content">
    <button type="button" class="close" @click="$emit('closeModal')">
      <span aria-hidden="true"><svg v-svg symbol="x" size="0 0 17 17" /></span>
    </button>
    <div class="modal-body">
      <div class="title-h2 pb-2">Landing</div>
      <img :src="`${landing.image}`" alt="" />
    </div>
  </div>
</template>
<script>
import "@/assets/css/_modal.scss";

export default {
  name: "LandingModal",
  props: {
    landing: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-body img {
  width: 100%;
}
</style>