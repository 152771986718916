<template>
  <div class="col-12 col-xl-full d-flex flex-column">
    <div class="section flex-grow-1 mb-4">
      <div class="row _offset30 align-items-center">
        <div class="col-12 col-lg-auto mr-auto">
          <h2>{{ $t("statistic.title") }}</h2>
        </div>
      </div>
      <div class="chart-statistics">
        <highcharts :options="chartOptions"></highcharts>
      </div>
      <div>
        <div
          v-if="data"
          class="row _offset30 size-14 mt--20 justify-content-center"
        >
          <div
            class="col-auto"
            @click.prevent="$emit('toggleChart', idx)"
            v-for="(el, idx) in data"
            :key="idx"
          >
            <div class="form-item pb-0">
              <div class="form-item__field">
                <label class="checkbox-switch d-flex">
                  <input type="checkbox" v-model="el.show" />
                  <span
                    class="checkbox-switch__visible _bg-gray _blue"
                    :style="`background: ${el.show ? el.color : '#CBCBCF'}`"
                  >
                  </span>
                  <span class="checkbox-switch__text"> {{ el.title }} </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";

export default {
  name: "StatisticChart",
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          height: 300,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Roboto",
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        legend: { enabled: false },
        xAxis: {
          crosshair: {
            color: "rgba(0,0,0,.02)",
          },
          categories: [],
          lineColor: "transparent",
          gridLineColor: "transparent",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#9292A3",
              fontSize: "12px",
            },
          },
        },
        yAxis: [
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            min: 0,
            gridLineColor: "#C1C1D0",
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
        ],
        tooltip: {
          useHTML: true,
          backgroundColor: "#fff",
          borderWidth: 1,
          padding: 15,
          shadow: {
            color: "#000",
            width: 30,
            opacity: 0.003,
          },
          borderColor: "#fff",
          borderRadius: 10,
          style: {
            fontSize: "13px",
            color: "#000",
          },
          shared: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.15,
            borderWidth: 0,
          },
          series: {
            borderRadius: 0,
          },
        },
        series: [],
      },
    };
  },
  watch: {
    data: {
      handler() {
        this.chartOptions.series = this.updatedOptions;
      },
      deep: true,
    },
  },
  mounted() {
    this.chartOptions.xAxis.categories = this.getLast7Days();
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    getLast7Days() {
      const today = new Date();
      const last7Days = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).slice(-2);

        const formattedDate = `${day}.${month}.${year}`;
        last7Days.unshift(formattedDate); // Add date to the beginning of the array
      }

      return last7Days;
    },
  },
  computed: {
    updatedOptions() {
      return this.data.filter((el) => el.show);
    },
  },
};
</script>