<template>
  <div class="row mt-3 mb-4">
    <div class="col-12">
      <ul class="tabs justify-content-start mb-0">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          class="col-sm-12 col-lg-auto pl-1 pr-1"
        >
          <button
            @click.prevent="$emit('setCurrentTab', tab)"
            :class="{ active: currentTab === tab }"
          >
            {{ $t(`${textPath ? textPath + "." + tab : tab}`) }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormatList",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
    textPath: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs li {
  @media (min-width: 576px) {
    flex: 1;
  }
}
</style>