<template>
  <div class="col-12 col-xl-310 col-md-6 d-flex">
    <div class="section _income mb-4">
      <div class="graph-head">
        <div class="graph-head__title">
          <h2>{{ $t("dashboard.general_info.income") }}</h2>
        </div>
        <div class="graph-head__desc">
          <p>
            {{ $t("dashboard.chart.check_income") }}
            <span class="txt-bg">{{ currentHour }}</span>
            {{ $t("dashboard.chart.up_to_UTC") }}
          </p>
        </div>
      </div>
      <div v-if="loadingChart" class="chart-income">
        <highcharts :options="chartOptions" />
      </div>
      <div class="graph-income-caption">
        <div class="row justify-content-center">
          <div class="col-auto">
            <p>{{ $t("yesterday") }}</p>
            <p>
              <strong v-if="data && data.yesterday">
                $ {{ data.yesterday.value }}
              </strong>
            </p>
          </div>
          <div class="col-auto">
            <p>{{ $t("today") }}</p>
            <p>
              <strong v-if="data && data.today">
                $ {{ data.today.value }}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";

export default {
  name: "IncomeChart",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    const vm = this;

    return {
      loadingChart: false,
      chartOptions: {
        tooltip: {
          backgroundColor: "#fff",
          borderWidth: 0,
          padding: 10,
          shadow: {
            color: "#000",
            width: 30,
            opacity: 0.003,
          },
          borderColor: "#fff",
          borderRadius: 10,
          style: {
            fontSize: "13px",
            color: "#000",
          },
          formatter: function () {
            if (
              vm.data &&
              vm.data.yesterday &&
              vm.data.yesterday.value &&
              vm.data.yesterday.value == this.y
            ) {
              return (
                '<span style="color: ' +
                vm.data.yesterday.color +
                '; font-weight: 900;">' +
                vm.data.yesterday.profit +
                "</span>"
              );
            } else {
              return (
                '<span style="color: ' +
                vm.data.today.color +
                '; font-weight: 900;">' +
                vm.data.today.profit +
                "</span>"
              );
            }
          },
          useHTML: true,
        },
        chart: {
          type: "column",
          height: 210,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Roboto",
          },
          events: {
            load: function () {
              (this.pointer.reset = () => {
                //this.series.forEach((serie, i) => serie.setState(i === 1 ?  'hover' :  'inactive') );
                this.tooltip.refresh(this.series[1].data[0]);
                return undefined;
              }),
                //this.series.forEach((serie, i) => serie.setState(i === 1 ?  'hover' :  'inactive') );
                this.tooltip.refresh(this.series[1].data[0]);
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: [""],
          lineColor: "transparent",
          gridLineColor: "transparent",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#91BAB6",
              fontSize: "12px",
            },
          },
        },
        yAxis: {
          min: 0,
          gridLineColor: "#91BAB6",
          gridLineWidth: 1,
          //gridLineDashStyle: 'Dash',
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#91BAB6",
              fontSize: "12px",
            },
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
          series: {
            borderRadius: 4,
          },
        },
        series: [
          {
            name: "Yesterday",
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, "#BBDAD8"],
                [1, "#3A7B7B"],
              ],
            },
            data: [1],
          },
          {
            name: "Today",
            color: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, "#BBDAD8"],
                [1, "#3A7B7B"],
              ],
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    data() {
      this.loadingChart = false;
      this.chartOptions.series[0].data[0] = this.data.yesterday.value;
      this.chartOptions.series[1].data[0] = this.data.today.value;
      setTimeout(() => {
        this.loadingChart = true;
      }, 0);
    },
  },
  computed: {
    currentHour() {
      const today = new Date();
      const hour = today.getHours();

      return `${hour}:00`;
    },
  },
};
</script>