<template>
  <div v-if="block">
    <div class="statistic mb-4">
      <div class="row">
        <div
          class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2n"
          v-for="(el, idx) in filteredBlock"
          :key="idx"
        >
          <div class="statistic__item">
            <div
              class="statistic__icon d-flex align-items-center justify-content-center"
            >
              <svg
                v-svg
                :symbol="setSvg(el[0])"
                :size="el[0] === 'ratio' ? '0 0 25 15' : '0 0 16 16'"
                role="presentation"
              />
            </div>

            <div class="statistic__label">
              {{ formatLabel(`dashboard.general_info.${el[0]}`) }}
            </div>
            <div class="statistic__value">{{ el[1] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/_statistic.scss";

export default {
  name: "GeneralInfo",
  props: {
    block: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      svgList: {
        uniq_clicks: "click",
        subscriptions: "menu-8",
        ratio: "percent",
        clicks: "link",
        cpc: "usd",
        income: "menu-6",
        views: "view",
        cpm: "usd",
        ctr: "percent",
        request: "view",
        rs_clicks: "click",
      },
    };
  },
  computed: {
    filteredBlock(){
    
      const hasCPM = this.block.some(item => item[0] === 'cpm');
      if (hasCPM) return this.block

      const order = ['clicks', 'subscriptions', 'uniq_clicks', 'cpc', 'ratio', 'income'];
      const dataMap = new Map(this.block);
      const reorderedData = order.map(key => [key, dataMap.get(key)]);
      return reorderedData
    }
  },
  methods: {
    setSvg(name) {
      return this.svgList[name] || "lock";
    },
    formatLabel(el) {
      return this.$t(el);
    },
  },
};
</script>