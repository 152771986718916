<template>
  <div class="col-12">
    <div class="section flex-grow-1 mb-md-4">
      <div class="row _offset30 align-items-center">
        <div class="col-12 col-lg-auto mr-auto">
          <h2>{{ title }}</h2>
        </div>

        <div
          class="row row-gap-10 align-items-baseline col-md-auto mb-15 min-200"
          v-if="!simple"
        >
          <custom-multiselect
            v-model="selectValue"
            :list="selectValues"
            class="chart-select"
            style="width: 200px"
            :allowEmpty="false"
          >
            <template v-slot:block="props">
              <span>
                <span>{{ formatLabel(props.value.title) }}</span>
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                <span>{{ formatLabel(props.value.title) }}</span>
              </span>
            </template>
          </custom-multiselect>
          <div class="col-12 col-md-auto size-14">
            <div class="row _offset30" id="chart-time-statistics-controls">
              <div
                class="col-auto"
                @click.prevent="$emit('toggleChart', index)"
                v-for="(el, index) in series"
                :key="index"
              >
                <div class="form-item pb-0">
                  <div class="form-item__field">
                    <label class="checkbox-switch d-flex">
                      <input
                        type="checkbox"
                        checked
                        value="0"
                        v-model="el.show"
                      />
                      <span
                        :style="`background: ${el.show ? el.color : '#CBCBCF'}`"
                        class="checkbox-switch__visible"
                      ></span>

                      <span class="checkbox-switch__text">{{
                        formatLabel(el.title)
                      }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 chart-time-statistics">
        <highcharts :options="chartOptions" :series="series"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "LinesChart",
  props: {
    series: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    simple: {
      type: Boolean,
      default: false,
    },
    customXAxis: {
      type: Array,
      default: null,
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      selectValue: {
        title: "dashboard.general_info.clicks",
        value: 1,
        id: "clicks",
      },
      selectValues: [
        {
          title: "dashboard.general_info.clicks",
          value: 1,
          id: "clicks",
        },
        {
          title: "statistic.table.subscriptions",
          value: 2,
          id: "subscriptions",
        },
        {
          title: "statistic.table.ratio",
          value: 3,
          id: "ratio",
        },
        {
          title: "dashboard.general_info.rs_clicks",
          value: 4,
          id: "rs_clicks",
        },
        {
          title: "statistic.table.cpc",
          value: 5,
          id: "cpc",
        },
        {
          title: "statistic.table.income",
          value: 6,
          id: "income",
        },
        {
          title: "statistic.table.cpm",
          value: 7,
          id: "cpm",
        },
        {
          title: "statistic.table.show",
          value: 8,
          id: "shows",
        },
      ],
      todaySwitcher: true,
      yesterdaySwitcher: true,
      yesterday2Switcher: true,
      chartOptions: {
        chart: {
          type: "line",
          height: 300,
          backgroundColor: "transparent",
          style: {
            fontFamily: "Roboto",
          },
        },
        title: {
          text: null,
        },
        yAxis: {
          gridLineColor: "#C1C1D0",
          gridLineWidth: 1,
          gridLineDashStyle: "Dash",
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#9292A3",
              fontSize: "12px",
            },
          },
        },
        xAxis: {
          categories: this.customXAxis || [],
          lineColor: "transparent",
          gridLineColor: "transparent",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#9292A3",
              fontSize: "12px",
            },
          },
        },

        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
        },

        tooltip: {
          useHTML: true,
          headerFormat: null,
          backgroundColor: "#fff",
          borderWidth: 1,
          padding: 15,
          shadow: {
            color: "#000",
            width: 30,
            opacity: 0.003,
          },
          borderColor: "#fff",
          borderRadius: 10,
          style: {
            fontSize: "13px",
            color: "#000",
          },
          crosshairs: {
            width: 1,
            color: "#C1C1D0",
            dashStyle: "Dash",
          },
          shared: true,
        },
        series: [],
        credits: {
          enabled: false,
        },
      },
    };
  },
  watch: {
    series: {
      handler() {
        this.chartOptions.series = this.updatedOptions;
      },
      deep: true,
    },
    customXAxis: {
      handler(val) {
        if (val) {
          this.chartOptions.xAxis.categories = val;
        }
      },
    },
    selectValue() {
      this.$emit("setCurrentValue", this.selectValue);
    },
  },
  mounted() {
    this.chartOptions.series = this.series;
    this.$emit("setCurrentValue", this.selectValue);
  },
  computed: {
    updatedOptions() {
      return this.series.filter((el) => el.show);
    },
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
  },
};
</script>

<style lang="scss">
.row-gap-10 {
  row-gap: 10px;
}

.chart-select {
  & .custom-multiselect-wrapper {
    background-color: #f0f0f3;
  }
}
</style>